import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('test-mail-to-admin-user');
  if (element === null) {
    return
  }
  const adminUserSelector = new Vue({
    el: "#test-mail-to-admin-user",
    
    data: {
    },

    watch: {
    },

    methods: {

      /**
       * 'Send test mail to admin user'検索条件のdrop-down menu管理
       */
      handleadminUserCheck: function() {
        let self = this;

        // 選択後、dropdown-menuが閉じてしまうのを防ぐ
        $('.adminUser-dropdown-menu').click(function (e) {
          e.stopPropagation();
        });

        // チェックボックスの状態が変わったら、ボタンのテキストを更新
        $('.adminUser-checkbox').off('change').on('change', function() {
          self.updateAdminUserDropdownButtonState();
        });

        // ラベルやチェックボックス本体以外の箇所（例: adminUser名の少し右）をクリックしても
        // チェックボックスの状態が変わるようにする
        $('.adminUser-dropdown-item').off('click').on('click', function (e) {
          // ラベルやチェックボックス本体をクリックした場合は何もしない（そもそもチェック入るので。）
          if ($(e.target).is('label, input')) return;

          // アイテム（=aタグ。ラベルやチェックボックスの親要素）をクリックした場合
          // チェックボックスの状態を変更する（toggle）
          let checkbox = $(this).find('input[type="checkbox"]');
          checkbox.prop('checked', !checkbox.prop('checked')).trigger('change');
        });
      },

      /**
       * 'Purchasing Manager'検索条件のdrop-down menuのボタンのテキストを更新
       */
      updateAdminUserDropdownButtonState: function() {
        // チェックされたチェックボックスの数を取得
        let checkedCount = $('.adminUser-checkbox:checked').length;
        // ドロップダウンメニューのボタン
        let $dropdownButton = $('#adminUserDropdownMenuButton');

        if (checkedCount > 0) {
          // 1つ以上チェックされている場合のテキスト
          const totalAdminUserCount = $('.adminUser-checkbox').length;
          const adminUserButtonText = checkedCount === totalAdminUserCount 
              ? 'All AdminUser Selected' 
              : `${checkedCount} AdminUser selected`;
            
            $dropdownButton.text(adminUserButtonText);
          $dropdownButton.removeClass('btn-light').addClass('btn-success');
        } else {
          // 1つもチェックされていない場合のテキスト
          $dropdownButton.text('Please select');
          $dropdownButton.removeClass('btn-success').addClass('btn-light');
        }
      },

        // clear selection ボタン
        clearAdminUserCheck () {
          $('#adminUser-clear-btn').on('click', function(){
            const allUnchecked = $('.adminUser-checkbox:checked').length === 0;
            const buttonTextIsDefault = $('#adminUserDropdownMenuButton').text().trim() === 'Please select';
  
            if (allUnchecked && buttonTextIsDefault) return;
  
            $('.adminUser-checkbox:checked').prop('checked', false).trigger('change');
            $('#adminUserDropdownMenuButton').text('Please select').removeClass('btn-success').addClass('btn-light');
          });
        }
    },
    mounted: function() {
      // こ要素が読み込まれた状態
      this.$nextTick(function() {
        this.handleadminUserCheck();
        this.updateAdminUserDropdownButtonState();
        this.clearAdminUserCheck();
      });
    }
  });
});