import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('target_country');
  if (element === null) {
    return
  }

  const countrySelector = new Vue({
    el: "#target_country",
    data: {
      selectedCountries: []
    },
    methods: {
        handleCountryCheck() {
          const self = this;
          
          // ドロップダウンメニューのクリック伝播を停止
          $('.country-dropdown-menu').click((e) => {
            e.stopPropagation();
          });
      
          // 全選択チェックボックスの処理を追加
          $('.select-all-checkbox').off('change').on('change', function() {
            const isChecked = $(this).prop('checked');
            $('.country-checkbox').prop('checked', isChecked);
            self.updateCountryDropdownButtonState();
          });
      
          // 個別のチェックボックス変更時の処理
          $('.country-checkbox').off('change').on('change', function() {
            self.updateSelectAllCheckbox();
            self.updateCountryDropdownButtonState();
          });
      
          // ドロップダウンアイテムクリック処理
          $('.country-dropdown-item').off('click').on('click', function(e) {
            if ($(e.target).is('label, input')) return;
            
            const checkbox = $(this).find('input[type="checkbox"]');
            checkbox.prop('checked', !checkbox.prop('checked')).trigger('change');
          });
        },
      
        // 全選択チェックボックスの状態を更新
        updateSelectAllCheckbox() {
          const totalCheckboxes = $('.country-checkbox').length;
          const checkedCheckboxes = $('.country-checkbox:checked').length;
          
          $('.select-all-checkbox').prop('checked', 
            totalCheckboxes === checkedCheckboxes && totalCheckboxes > 0
          );
        },
      
        updateCountryDropdownButtonState() {
          const checkedCount = $('.country-checkbox:checked').length;
          const $dropdownButton = $('#countryDropdownMenuButton');
      
          if (checkedCount > 0) {
            const totalCount = $('.country-checkbox').length;
            const buttonText = checkedCount === totalCount 
              ? 'All Countries Selected' 
              : `${checkedCount} countries selected`;
            
            $dropdownButton.text(buttonText);
            $dropdownButton.removeClass('btn-light').addClass('btn-success');
          } else {
            $dropdownButton.text('Please select countries');
            $dropdownButton.removeClass('btn-success').addClass('btn-light');
          }
        },

        // clear selection ボタン
        clearCountryCheck () {
          $('#country-clear-btn').on('click', function(){
            const allUnchecked = $('.country-checkbox:checked').length === 0;
            const buttonTextIsDefault = $('#countryDropdownMenuButton').text().trim() === 'Please select countries';
  
            if (allUnchecked && buttonTextIsDefault) return;
  
            $('.country-checkbox:checked').prop('checked', false).trigger('change');
            $('#countryDropdownMenuButton').text('Please select countries').removeClass('btn-success').addClass('btn-light');
          });
        }
      },
    mounted() {
      this.$nextTick(() => {
        this.handleCountryCheck();
        this.updateCountryDropdownButtonState();
        this.updateSelectAllCheckbox();
        this.clearCountryCheck();
      });
    }
  });
});