// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("bootstrap");
require("@fortawesome/fontawesome-free");
require("jquery.cookie/jquery.cookie");
require("packs/raty")
require("packs/raty_view")
require("trix")
require("@rails/actiontext")
require('packs/message_chat_vue');
require('packs/admin_order_list_per_buyer_vue');
require('packs/admin_invoice_form_vue');
require('packs/admin_amend_bl_form_vue');
require('packs/admin_invoice_search_vue');
require('packs/user_invoice_form_vue');
require('packs/vip_invoice_form_vue');
require('packs/vip_partner_code_vue');
require('packs/admin_partner_code_vue');
require('packs/admin_orders_index_vue');
require('packs/admin_orders_edit_vue');
require('packs/admin_orders_show_vue');
require('packs/admin_orders_recommend_form_vue');
require('packs/admin_request_payment_confirmations_index_vue');
require('packs/admin_paypal_invoice_index_vue');
require('packs/admin_request_payment_confirmations_vue');
require('packs/admin_unarrived_form_vue');
require('packs/transaction_item_cars_edit_vue');
require('packs/transaction_item_service_edit_vue');
require('packs/admin_payment_managements_document_files_modal');
require('packs/admin_vertice_car_model_form_vue');
require('packs/editor');
require('packs/crop_image');
require('packs/users_registrations_edit_vue');
require('packs/auction_list_vue');
require('packs/auction_search_vue');
require('packs/auction_bids_index_vue');
require('packs/admin_bids_index_vue');
require('packs/admin_sidebar_hamburger_menu_vue');
require("chartkick/chart.js");
require('packs/channels/appearance_channel');
require('packs/channels/appearing_channel');
require('packs/channels/download_status_channel');
require('packs/real_bids');
require('packs/admin_review_form_vue');
require('packs/admin_carried_forwards_form_vue');
require('packs/admin_carried_forwards_transfer_vue');
require('packs/admin_auction_deposits_form_vue');
require('packs/admin_users_index');
require('packs/admin_csv_for_aggregates_vue');
require('packs/header_search_autocomplete_vue');
require('packs/admin_car_index_search_vue');
require('packs/admin_ships_edit_vue');
require('packs/faq_chatbot_vue');
require('packs/admin_port_form_vue');
require('packs/admin_top_page_banner_form_vue');
require('packs/mail_magazine_country_select');
require('packs/mail_magazine_admin_user_select');
// Add Buttons to Rails ActionText toolbar
var Trix  = require("trix")
// console.log("Config", Trix.config);

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
}
Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
}

const {lang} = Trix.config;

Trix.config.toolbar = {
  getDefaultHTML() { return `\
<div class="trix-button-row">
  <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
  </span>
  <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
    <button type="button" class="trix-button  " data-trix-attribute="heading1" title="h1" tabindex="-1">h1</button>
    <button type="button" class="trix-button  " data-trix-attribute="heading2" title="h2" tabindex="-1">h2</button>
    <button type="button" class="trix-button  " data-trix-attribute="heading3" title="h3" tabindex="-1">h3</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${lang.code}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
  </span>
  <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
  </span>
  <span class="trix-button-group-spacer"></span>
  <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
  </span>
</div>
<div class="trix-dialogs" data-trix-dialogs>
  <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
    <div class="trix-dialog__link-fields">
      <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
      <div class="trix-button-group">
        <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
        <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
      </div>
    </div>
  </div>
</div>\
`; }
};